import React from "react";
import PropTypes from "prop-types";
import "./buttonContainer.scss";

const ButtonContainer = ({ direction, padding, maxWidth, children }) => {
  return (
    <div
      className='button-container'
      style={{
        flexDirection: `${direction === "vertical" ? "column" : "row"}`,
        padding: `0 ${padding}px`,
        maxWidth: `${maxWidth + 3}px`,
      }}>
      {children}
    </div>
  );
};

ButtonContainer.propTypes = {
  direction: PropTypes.string,
  padding: PropTypes.number,
  maxWidth: PropTypes.number,
};

export default ButtonContainer;
