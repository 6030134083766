import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import mapboxgl from "!mapbox-gl";
import "./map.scss";
import targetLock from "../assets/target-lock.png";
import cupIcon from "../assets/cup.png";
import userIcon from "../assets/user-indicator.png";
import userIconWithHeading from "../assets/user-indicator--with-heading.png";

mapboxgl.accessToken =
  "pk.eyJ1IjoiemFjaHRlbWtpbiIsImEiOiJjbDJxd3VvbnAwNTUwM2N0MGVrYmF2Y2ZpIn0.s7e69zcQSOLP3sMI-pjEPA";

const Map = ({
  mapSize,
  rotationDeg,
  userLat,
  userLong,
  places,
  currentPlaceBeingViewed,
  setMapEdges,
  shouldReDoSearch,
  placeDetailsIsOpen,
  bottomPadding,
  compassIsActive,
  compassIsInverted,
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: {
        version: 8,
        sources: {
          "raster-tiles": {
            type: "raster",
            tiles: [`https://labs.noshado.ws/pixel-map-tiles/{x}/{y}.jpg`],
            tileSize: 256,
          },
        },
        layers: [
          {
            id: "simple-tiles",
            type: "raster",
            source: "raster-tiles",
            minzoom: 15,
            maxzoom: 16,
          },
        ],
      },
      center: [userLong, userLat],
      bearing: rotationDeg,
      zoom: 15,
    });

    map.current.boxZoom.disable();
    map.current.doubleClickZoom.disable();
    map.current.dragPan.disable();
    map.current.dragRotate.disable();
    map.current.keyboard.disable();
    map.current.scrollZoom.disable();
    map.current.touchZoomRotate.disable();
  }, [userLong, userLat, rotationDeg, setMapEdges]);

  const placeMarkers = useRef([]);
  const targetMarker = useRef(null);
  const userMarker = useRef(null);

  useEffect(() => {
    const bounds = map.current.getBounds();
    const northEdge = bounds.getNorth();
    const southEdge = bounds.getSouth();
    const westEdge = bounds.getWest();
    const eastEdge = bounds.getEast();

    setMapEdges([northEdge, southEdge, westEdge, eastEdge]);
  }, [setMapEdges, shouldReDoSearch]);

  useEffect(() => {
    if (places.length > 0) {
      const { latlng } = places[currentPlaceBeingViewed];

      const placeUserMidPoint = [
        (userLong + latlng.longitude) / 2,
        (userLat + latlng.latitude) / 2,
      ];

      const placePoint = [latlng.longitude, latlng.latitude];

      const userLocation = [userLong, userLat];

      let centerPoint;
      let bottomOffset;

      if (placeDetailsIsOpen) {
        centerPoint = placePoint;
        bottomOffset = 185;
      } else {
        centerPoint = shouldReDoSearch ? userLocation : placeUserMidPoint;
        bottomOffset = 236;
      }

      map.current.easeTo({
        center: centerPoint,
        bearing: compassIsActive && !compassIsInverted ? rotationDeg : 0,
        padding: { top: 120, bottom: bottomPadding + bottomOffset },
        duration: 250,
      });

      if (targetMarker.current && placeMarkers.current) {
        targetMarker.current.setLngLat([latlng.longitude, latlng.latitude]);

        places.forEach((place, index) => {
          const placeCoords = [place.latlng.longitude, place.latlng.latitude];
          placeMarkers.current[index].setLngLat(placeCoords);
        });
        return;
      }

      for (const place of places) {
        const placeCoords = [place.latlng.longitude, place.latlng.latitude];

        const el = document.createElement("div");
        el.className = "marker";
        el.style.width = "40px";
        el.style.height = "40px";
        el.style.backgroundImage = `url(${cupIcon})`;
        el.style.backgroundSize = "cover";

        let placeMarker = new mapboxgl.Marker({
          element: el,
          anchor: "center",
        })
          .setLngLat(placeCoords)
          .addTo(map.current);

        placeMarkers.current.push(placeMarker);
      }

      const targetEl = document.createElement("div");
      targetEl.className = "target";
      targetEl.style.width = "64px";
      targetEl.style.height = "64px";
      targetEl.style.backgroundImage = `url(${targetLock})`;
      targetEl.style.backgroundSize = "100%";

      targetMarker.current = new mapboxgl.Marker({
        element: targetEl,
        anchor: "center",
      })
        .setLngLat([latlng.longitude, latlng.latitude])
        .addTo(map.current);
    }
  }, [
    places,
    currentPlaceBeingViewed,
    userLat,
    userLong,
    rotationDeg,
    shouldReDoSearch,
    placeDetailsIsOpen,
    bottomPadding,
    compassIsInverted,
    compassIsActive,
  ]);

  useEffect(() => {
    if (userMarker.current) {
      userMarker.current.setLngLat([userLong, userLat]);
      userMarker.current.setRotation(
        compassIsActive && !compassIsInverted ? 0 : rotationDeg
      );
      userMarker.current._element.style.backgroundImage = compassIsActive
        ? `url(${userIconWithHeading})`
        : `url(${userIcon})`;
      return;
    }

    const userEl = document.createElement("div");
    userEl.className = "user-location-indicator";
    userEl.style.width = "64px";
    userEl.style.height = "64px";
    userEl.style.backgroundImage = `url(${userIcon})`;
    userEl.style.backgroundSize = "cover";

    userMarker.current = new mapboxgl.Marker({
      element: userEl,
      anchor: "center",
    })
      .setLngLat([userLong, userLat])
      .setRotation(compassIsActive && !compassIsInverted ? 0 : rotationDeg)
      .addTo(map.current);
  }, [userLong, userLat, compassIsActive, compassIsInverted, rotationDeg]);

  return (
    <div
      className='map-wrapper'
      style={{
        position: "absolute",
        width: `${mapSize.width}px`,
        height: `calc(${mapSize.height}px + env(safe-area-inset-top))`,
        top: "0",
        left: "50%",
        transform: `translate(-50%, 0)`,
      }}>
      <div
        className='map'
        id='map'
        ref={mapContainer}
        style={{
          width: `100%`,
          height: `100%`,
          top: "50%",
          left: "50%",
          transform: `translate(-50%, -50%)`,
        }}></div>
    </div>
  );
};

Map.propTypes = {
  mapSize: PropTypes.object,
  rotationDeg: PropTypes.number,
  userLat: PropTypes.number,
  userLong: PropTypes.number,
  places: PropTypes.array,
  currentPlaceBeingViewed: PropTypes.number,
  placeDetailsIsOpen: PropTypes.bool,
  setMapEdges: PropTypes.func,
  compassIsActive: PropTypes.bool,
};

export default Map;
