import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./compassButton.scss";

const CompassButton = ({
  setRotation,
  setOnCompassIsActive,
  setOnCompassInversion,
  bottomPos,
}) => {
  const [compassIsActive, setCompassIsActive] = useState(false);
  const [compassIsInverted, setCompassIsInverted] = useState(false);
  const [deviceOrientation, setDeviceOrientation] = useState(0);

  const handleDeviceRotation = (e) => {
    setDeviceOrientation(e.webkitCompassHeading || Math.abs(e.alpha - 360));
  };

  useEffect(() => {
    setRotation(deviceOrientation);
    setOnCompassIsActive(compassIsActive);
    setOnCompassInversion(compassIsInverted);
  }, [
    deviceOrientation,
    setRotation,
    compassIsActive,
    setOnCompassIsActive,
    setOnCompassInversion,
    compassIsInverted,
  ]);

  const toggleCompass = () => {
    if (!compassIsActive) {
      if (window.DeviceOrientationEvent) {
        if (
          typeof window.DeviceOrientationEvent.requestPermission === "function"
        ) {
          DeviceOrientationEvent.requestPermission()
            .then((response) => {
              if (response === "granted") {
                window.addEventListener(
                  "deviceorientation",
                  handleDeviceRotation,
                  true
                );
                setCompassIsActive(true);

                return () => {
                  setCompassIsActive(false);
                  window.removeEventListener(
                    "deviceorientation",
                    handleDeviceRotation
                  );
                };
              } else {
                console.log("has to be allowed!");
              }
            })
            .catch(() => alert("not supported"));
        } else {
          window.addEventListener(
            "deviceorientation",
            handleDeviceRotation,
            true
          );
          setCompassIsActive(true);
        }
      } else {
        alert("compass not supported");
      }
    } else if (compassIsActive && !compassIsInverted) {
      setCompassIsInverted(true);
    } else {
      setCompassIsInverted(false);
    }
  };

  return (
    <div className='compass-button' onClick={() => toggleCompass()}>
      <p className='compass-label'>Rotation </p>
      <div
        className={`compass-state ${
          compassIsActive && !compassIsInverted ? "is-current-state" : ""
        }`}>
        Map
      </div>
      <div
        className={`compass-state ${
          compassIsActive && compassIsInverted ? "is-current-state" : ""
        }`}>
        You
      </div>
    </div>
  );
};

CompassButton.propTypes = {
  compassIsActive: PropTypes.bool,
  setCompassIsActive: PropTypes.func,
  onSetCompassInversion: PropTypes.func,
  bottomPos: PropTypes.number,
};

export default CompassButton;
