import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    .bottom-chrome {
        background-color: ${({ theme }) => theme.bottomChrome};
    }

    .scan-lines {
        background-color: ${({ theme }) => theme.mapOverlay};
        mix-blend-mode: ${({ theme }) => theme.mapBlendMode};
    }

    .start-screen {
        background-color: ${({ theme }) => theme.statusBarBackground};
    }

    .place-card {
        background-color: ${({ theme }) => theme.cardBackground};
        border-color: ${({ theme }) => theme.cardBackground};
        color: ${({ theme }) => theme.cardText};
    }

    .place-card-empty {
        background-color: ${({ theme }) => theme.cardBackground};
        border-color: ${({ theme }) => theme.cardBackground};
        color: ${({ theme }) => theme.cardText};
    }

    .index {
        background-color: ${({ theme }) => theme.indexBackgroundColor};
        border: 3px solid ${({ theme }) => theme.cardBackground};
        color: ${({ theme }) => theme.indexColor};
        box-shadow: 0 0 0 1.5px ${({ theme }) =>
          theme.indexShadowColor}, 0 4px 0 1px rgba(0, 0, 0, 0.15);
    }

    .photo,
    .photo-placeholder {
        background-color: ${({ theme }) => theme.cardBackground};
        color: ${({ theme }) => theme.cardBackground};
        box-shadow: ${({ theme }) => theme.photoShadow};
    }

    .reviews::before {
        background: linear-gradient(
            90deg,
            ${({ theme }) => theme.cardBackground} 10%,
            rgba(255, 255, 255, 0) 100%
        );
    }

    .reviews::after {
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 10%,
            ${({ theme }) => theme.cardBackground} 100%
        );
    }

    .ratingBar {
        color: ${({ theme }) => theme.ratingBarBorder};
    }

    .heads-up-display {
        background-color: ${({ theme }) => theme.hudBackgroundColor};
    }
`;
