import React from "react";
import PropTypes from "prop-types";
import "./bannerHeader.scss";

const BannerHeader = ({ children }) => {
  return <div className='banner-header-wrapper'>{children}</div>;
};

BannerHeader.propTypes = {
  children: PropTypes.node,
};

export default BannerHeader;
