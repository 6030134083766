import React from "react";
import PropTypes from "prop-types";
import "./weatherIndicator.scss";

const WeatherIndicator = ({ currentTheme }) => {
  return (
    <div className='weather-indicator'>
      <p className='weather-label'>weather</p>
      <p className='condition-label'>{currentTheme.condition}</p>
      <div className='icon-container'>{currentTheme.icon}</div>
    </div>
  );
};

WeatherIndicator.propTypes = {
  currentTheme: PropTypes.object,
  onSwitchTheme: PropTypes.func,
};

export default WeatherIndicator;
