import React from "react";
import PropTypes from "prop-types";
import "./bottomChrome.scss";

const BottomChrome = ({ children, isExpanded }) => {
  return (
    <div className={`bottom-chrome ${isExpanded ? "expanded" : ""}`}>
      {children}
    </div>
  );
};

BottomChrome.propTypes = {
  children: PropTypes.node,
  isExpanded: PropTypes.bool,
};

export default BottomChrome;
