import React from "react";
import PropTypes from "prop-types";
import "./loadingScreen.scss";
import cupIcon from "../assets/cup.png";
import drops from "../assets/coffee-drops.png";

const LoadingScreen = ({
  userLocationIsAccquired,
  backgroundColor,
  height,
}) => {
  if (!userLocationIsAccquired) {
    return (
      <div
        className='loading-screen'
        style={{
          backgroundColor: `${backgroundColor}`,
          height: `calc(${height}px + env(safe-area-inset-top))`,
          top: `-100px`,
        }}>
        <img className='drops-loader' src={drops} alt='8-bit coffee cup' />
        <img className='cup-loader' src={cupIcon} alt='8-bit coffee cup' />
      </div>
    );
  }
};

LoadingScreen.propTypes = {
  userLocationIsAccquired: PropTypes.bool,
  backgroundColor: PropTypes.string,
  height: PropTypes.number,
};

export default LoadingScreen;
