import { ReactComponent as SunIcon } from "./assets/sun.svg";
import { ReactComponent as CloudIcon } from "./assets/cloud.svg";
import { ReactComponent as RainIcon } from "./assets/rain.svg";
import { ReactComponent as MoonIcon } from "./assets/moon.svg";

export const weatherThemes = {
  default: {
    name: "default",
    condition: "Loading...",
    mapOverlay: "#5145d351",
    mapBlendMode: "soft-light",
    bottomChrome: "#da9d71",
    themeSwitcherBackground: "#2e8af7",
    statusBarBackground: "#7AD978",
    cardBackground: "rgba(255 255 255 / 100%)",
    cardText: "#000000",
    ratingBarBorder: "rgba(0 0 0 / 17%)",
    photoShadow: "0 0 0 2px rgba(0 0 0 / 20%)",
    indexBackgroundColor: "#e0e0e0",
    indexColor: "rgba(0 0 0 / 80%)",
    indexShadowColor: "rgba(0 0 0 / 45%)",
    hudBackgroundColor: "#00636e",
  },
  "day-sunny": {
    name: "day-sunny",
    condition: "Sunny",
    icon: <SunIcon />,
    mapOverlay: "#5145d351",
    mapBlendMode: "soft-light",
    bottomChrome: "#da9d71",
    themeSwitcherBackground: "#2e8af7",
    statusBarBackground: "#7AD978",
    cardBackground: "rgba(255 255 255 / 100%)",
    cardText: "#000000",
    ratingBarBorder: "rgba(0 0 0 / 17%)",
    photoShadow: "0 0 0 2px rgba(0 0 0 / 20%)",
    indexBackgroundColor: "#e0e0e0",
    indexColor: "rgba(0 0 0 / 80%)",
    indexShadowColor: "rgba(0 0 0 / 45%)",
    hudBackgroundColor: "#00636e",
  },
  "day-cloudy": {
    name: "day-cloudy",
    condition: "Cloudy",
    icon: <CloudIcon />,
    mapOverlay: "#5145D3",
    mapBlendMode: "soft-light",
    bottomChrome: "#D28D7D",
    themeSwitcherBackground: "#2e8af7",
    statusBarBackground: "#75D494",
    cardBackground: "rgba(255 255 255 / 100%)",
    cardText: "#000000",
    ratingBarBorder: "rgba(0 0 0 / 17%)",
    photoShadow: "0 0 0 2px rgba(0 0 0 / 20%)",
    indexBackgroundColor: "#e0e0e0",
    indexColor: "rgba(0 0 0 / 80%)",
    indexShadowColor: "rgba(0 0 0 / 45%)",
    hudBackgroundColor: "#005f3d",
  },

  "day-rain": {
    name: "day-rain",
    condition: "Raining",
    icon: <RainIcon />,
    mapOverlay: "#2400FF",
    bottomChrome: "#D2827D",
    mapBlendMode: "soft-light",
    themeSwitcherBackground: "#2e8af7",
    statusBarBackground: "#65C7A5",
    cardBackground: "rgba(255 255 255 / 100%)",
    cardText: "#000000",
    ratingBarBorder: "rgba(0 0 0 / 17%)",
    photoShadow: "0 0 0 2px rgba(0 0 0 / 20%)",
    indexBackgroundColor: "#e0e0e0",
    indexColor: "rgba(0 0 0 / 80%)",
    indexShadowColor: "rgba(0 0 0 / 45%)",
    hudBackgroundColor: "#007729",
  },
  "day-snow": {
    // TODO: Need to update (just a copy of the rain theme right now)
    name: "day-snow",
    condition: "Snowing",
    icon: <CloudIcon />,
    mapOverlay: "#2400FF",
    bottomChrome: "#D2827D",
    mapBlendMode: "soft-light",
    themeSwitcherBackground: "#2e8af7",
    statusBarBackground: "#65C7A5",
    cardBackground: "rgba(255 255 255 / 100%)",
    cardText: "#000000",
    ratingBarBorder: "rgba(0 0 0 / 17%)",
    photoShadow: "0 0 0 2px rgba(0 0 0 / 20%)",
    indexBackgroundColor: "#e0e0e0",
    indexColor: "rgba(0 0 0 / 80%)",
    indexShadowColor: "rgba(0 0 0 / 45%)",
    hudBackgroundColor: "#007729",
  },
  "night-clear": {
    name: "night-clear",
    condition: "Clear",
    icon: <MoonIcon />,
    mapOverlay: "#3d54d2",
    mapBlendMode: "overlay",
    bottomChrome: "#315a71",
    themeSwitcherBackground: "#2e8af7",
    statusBarBackground: "#65C7A5",
    cardBackground: "rgba(46 58 68 / 100%)",
    cardText: "#ffffff",
    ratingBarBorder: "rgba(255 255 255 / 20%)",
    photoShadow: "0 0 0 2px rgba(255 255 255 / 20%)",
    indexBackgroundColor: "rgba(0 0 0 / 20%)",
    indexColor: "rgba(255 255 255 / 97%)",
    indexShadowColor: "rgba(0 0 0 / 30%)",
    hudBackgroundColor: "#0c2132",
  },
  "night-cloudy": {
    name: "night-cloudy",
    condition: "Cloudy",
    icon: <CloudIcon />,
    mapOverlay: "#0026ff",
    mapBlendMode: "overlay",
    bottomChrome: "#315a71",
    themeSwitcherBackground: "#2e8af7",
    statusBarBackground: "#65C7A5",
    cardBackground: "rgba(46 58 68 / 100%)",
    cardText: "#ffffff",
    ratingBarBorder: "rgba(255 255 255 / 20%)",
    photoShadow: "0 0 0 2px rgba(255 255 255 / 20%)",
    indexBackgroundColor: "rgba(0 0 0 / 20%)",
    indexColor: "rgba(255 255 255 / 97%)",
    indexShadowColor: "rgba(0 0 0 / 30%)",
    hudBackgroundColor: "#02381d",
  },
  "night-rain": {
    name: "night-rain",
    condition: "Raining",
    icon: <RainIcon />,
    mapOverlay: "#0026ff",
    mapBlendMode: "overlay",
    bottomChrome: "#315a71",
    themeSwitcherBackground: "#2e8af7",
    statusBarBackground: "#65C7A5",
    cardBackground: "rgba(46 58 68 / 100%)",
    cardText: "#ffffff",
    ratingBarBorder: "rgba(255 255 255 / 20%)",
    photoShadow: "0 0 0 2px rgba(255 255 255 / 20%)",
    indexBackgroundColor: "rgba(0 0 0 / 20%)",
    indexColor: "rgba(255 255 255 / 97%)",
    indexShadowColor: "rgba(0 0 0 / 30%)",
    hudBackgroundColor: "#0c2132",
  },
  "night-snow": {
    // TODO: Need to update (just a copy of the rain theme right now)
    name: "night-snow",
    condition: "Snowing",
    icon: <CloudIcon />,
    mapOverlay: "#0026ff",
    mapBlendMode: "overlay",
    bottomChrome: "#315a71",
    themeSwitcherBackground: "#2e8af7",
    statusBarBackground: "#65C7A5",
    cardBackground: "rgba(46 58 68 / 100%)",
    cardText: "#ffffff",
    ratingBarBorder: "rgba(255 255 255 / 20%)",
    photoShadow: "0 0 0 2px rgba(255 255 255 / 20%)",
    indexBackgroundColor: "rgba(0 0 0 / 20%)",
    indexColor: "rgba(255 255 255 / 97%)",
    indexShadowColor: "rgba(0 0 0 / 30%)",
    hudBackgroundColor: "#02381d",
  },
};
