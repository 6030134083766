function getPlaces(
  keyword,
  type,
  lat,
  lng,
  limit,
  viewX,
  viewY,
  scale,
  callback
) {
  return fetch(
    // `https://524ho7vm5sbczjshzmgb4abrzm0buupp.lambda-url.us-east-1.on.aws/`, // Sample response for debugging without using Google Places API quota
    `https://ctv4itr4gli5mb36gpw63cmp3m0lrxek.lambda-url.us-east-1.on.aws/?keyword=${keyword}&type=${type}&lat=${lat}&lng=${lng}&limit=${limit}&viewport=${viewX}x${viewY}&scale=${scale}`,
    {
      accept: "application/json",
    }
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(callback);
}

function getWeather(lat, lng, callback) {
  return fetch(
    `https://xbinwbuc7bnkqonzu3kx654jsi0lcgrs.lambda-url.us-east-1.on.aws/?location=${lat},${lng}`,
    {
      accept: "application/json",
    }
  )
    .then(checkStatus)
    .then(parseJSON)
    .then(callback);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(`HTTP Error ${response.statusText}`);
  error.status = response.statusText;
  error.response = response;
  console.log(error);
  throw error;
}

function parseJSON(response) {
  return response.json();
}

const Client = { getPlaces, getWeather };
export default Client;
