import React from "react";
import PropTypes from "prop-types";
import ChunkyButton from "./chunkyButton";
import "./reDoSearchButton.scss";

const ReDoSearchButton = ({ isVisible, handleClick }) => {
  return (
    <div className={`re-do-search-button ${isVisible ? "is-visible" : ""}`}>
      <ChunkyButton backgroundColor='rgba(0,0,0,0.3)' handleClick={handleClick}>
        Search This Area
      </ChunkyButton>
    </div>
  );
};

ReDoSearchButton.propTypes = {
  isVisible: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default ReDoSearchButton;
