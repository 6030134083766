import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./placeCardCarousel.scss";

const PlaceCardCarousel = ({
  width,
  maxWidth,
  screenWidth,
  defaultPadding,
  increment,
  currentPlaceBeingViewed,
  finishedLoadingPlaces,
  children,
}) => {
  const [padding, setPadding] = useState(defaultPadding);

  useEffect(() => {
    setPadding(
      screenWidth >= maxWidth
        ? (window.innerWidth - maxWidth - 3) / 2
        : defaultPadding
    );
  }, [maxWidth, defaultPadding, screenWidth]);

  return (
    <div
      className='place-card-carousel'
      style={{
        width: finishedLoadingPlaces ? `${width}px` : '100%',
        paddingLeft: `${padding}px`,
        transform: `translateX(-${increment * currentPlaceBeingViewed}px)`,
      }}>
      {children}
    </div>
  );
};

PlaceCardCarousel.propTypes = {
  width: PropTypes.number,
  maxWidth: PropTypes.number,
  screenWidth: PropTypes.number,
  defaultPadding: PropTypes.number,
  increment: PropTypes.number,
  currentPlaceBeingViewed: PropTypes.number,
  finishedLoadingPlaces: PropTypes.bool,
  children: PropTypes.node,
};

export default PlaceCardCarousel;
