import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ScanLines from "./scanLines";
import "./decorativeFrame.scss";

const DecorativeFrame = ({ children, style, className, simple, scanLines }) => {
  const elHeight = useRef(null);
  const [componentHeight, setComponentHeight] = useState(0);

  useEffect(() => {
    setComponentHeight(elHeight.current.clientHeight);
  }, [elHeight]);

  return (
    <div
      ref={elHeight}
      className={`decorative-frame ${
        simple ? "decorative-frame--simple" : ""
      } ${className ? className : ""}`}
      style={style ? style : {}}>
      {children}
      {scanLines && <ScanLines lineHeight={2} height={componentHeight} />}
    </div>
  );
};

DecorativeFrame.defaultProps = {
  simple: true,
  scanLines: false,
};

DecorativeFrame.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  simple: PropTypes.bool,
  scanLines: PropTypes.bool,
};

export default DecorativeFrame;
