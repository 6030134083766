import React from "react";
import PropTypes from "prop-types";
import DecorativeFrame from "./decorativeFrame";
import "./placeCardEmpty.scss";

const PlaceCardEmpty = ({ index, cardWidth, rightMargin }) => {
  return (
    <DecorativeFrame
      simple={false}
      className={`place-card-empty`}></DecorativeFrame>
  );
};

PlaceCardEmpty.propTypes = {
  cardWidth: PropTypes.number,
  rightMargin: PropTypes.number,
};

export default PlaceCardEmpty;
