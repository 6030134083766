import React, { useState, useEffect, useRef } from "react";
import ScanLines from "./scanLines";
import PropTypes from "prop-types";
import "./headsUpDisplay.scss";

const HeadsUpDisplay = ({ isVisible, finishedLoadingPlaces, children }) => {
  const elHeight = useRef(null);
  const [componentHeight, setComponentHeight] = useState(0);

  useEffect(() => {
    setComponentHeight(elHeight.current.clientHeight);
  }, []);

  return (
    <div
      className={`heads-up-display ${isVisible ? "is-visible" : ""}`}
      ref={elHeight}>
      {!finishedLoadingPlaces && (
        <p className='places-loader'>Loading Places...</p>
      )}
      {finishedLoadingPlaces && children}
      <ScanLines lineHeight={2} height={componentHeight} />
    </div>
  );
};

HeadsUpDisplay.propTypes = {
  isVisible: PropTypes.bool,
  finishedLoadingPlaces: PropTypes.bool,
};

export default HeadsUpDisplay;
