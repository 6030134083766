import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import DecorativeFrame from "./decorativeFrame";
import "./placeCard.scss";
import missingImage from "../assets/missing-image.png";

function convertRange(value, r1, r2) { 
    return (value - r1[0]) * (r2[1] - r2[0]) / (r1[1] - r1[0]) + r2[0];
}

const SimpleCardContent = ({ index, place, cardWidth, simpleAddress }) => {
  return (
    <div className='card-wrapper'>
      <p className='index'>{index + 1}</p>
      <div className='info-wrapper'>
        <h3 className='name' style={{ width: `${cardWidth - 90}px` }}>
          {place.name}
        </h3>
        <p className='address'>{simpleAddress}</p>
      </div>
    </div>
  );
};

const RichCardContent = ({
  place,
  cardWidth,
  photos,
  photosView,
  missingPhotos,
  getDirections,
  simpleAddress,
  fullAddress,
  ratingDisplay,
  rating,
  ratingColor,
  reviewsView,
}) => {
  return (
    <div className='card-wrapper'>
      <div className='info-wrapper'>
        <h3 className='name' style={{ width: `${cardWidth - 48}px` }}>
          {place.name}
        </h3>
        <div
          className={`photos count${photos.length ? photos.length : ""}`}
          style={{ width: `${cardWidth - 51}px` }}>
          {photosView}
          {missingPhotos.map((i) => {
            return (
              <div
                className='photo'
                style={{
                  height: `${(cardWidth - 95) / 3}px`,
                  width: `${(cardWidth - 95) / 3}px`,
                }}
                key={`${Math.random()}-${i}`}>
                <img src={missingImage} alt='' />
              </div>
            );
          })}
        </div>
        <div
          onClick={fullAddress === 'UNKNOWN' ? null : getDirections}
          className={`location ${fullAddress === 'UNKNOWN' ? '' : 'clickable'}`}
        >
          <label>Location</label>
          <p className='address line1' style={{ width: `${cardWidth - 48}px` }}>{simpleAddress}</p>
          <p className='address line2' style={{ width: `${cardWidth - 48}px` }}>{fullAddress}</p>
        </div>
        <div className='ratingLabelAndValue'>
          <label>Rating</label>
          <p className='rating'>{ratingDisplay}</p>
        </div>
        <div className='ratingBar'>
          <div
            className='filled'
            style={{
              width: `${Math.min(Math.max(parseInt(convertRange(rating, [0, 5], [0, 100])), 0), 100)}%`,
              backgroundColor: ratingColor,
            }}></div>
        </div>
        <label>{place.customReviews ? 'Author Notes' : 'Public Reviews'}</label>
        <div className='reviews'>{reviewsView}</div>
      </div>
    </div>
  );
};

const PlaceCard = ({
  place,
  index,
  cardWidth,
  rightMargin,
  placeDetailsIsOpen,
  updateMapPadding,
}) => {
  const [rating, setRating] = useState(10);
  const [ratingDisplay, setRatingDisplay] = useState("???");
  const [ratingColor, setRatingColor] = useState("rgba(0, 0, 0, 0.08)");
  const [reviewsString, setReviewsString] = useState();
  const [reviewsView, setReviewsView] = useState();
  const [photos, setPhotos] = useState([[], [], []]);
  const [photosView, setPhotosView] = useState();
  const [missingPhotos, setmissingPhotos] = useState([]);

  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  let delimiter = ',';
  let simpleAddress;
  let fullAddress = 'UNKNOWN';
  if (place.address && place.address.includes(delimiter)) {
    simpleAddress = place.address.split(delimiter)[0];
    fullAddress = place.address.slice(
      place.address.indexOf(delimiter) + delimiter.length
    );
  }
  
  const ratingColors = useMemo(
    () => ({
      0: "#EA5B5B",
      1: "#E39F6E",
      2: "#E3D76E",
      3: "#A1E36E",
      4: "#6EE379",
      5: "#6EE379",
    }),
    []
  );

  const getDirections = () => {
    window.open(place.urls.googleMaps, "_blank");
  };

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [placeDetailsIsOpen, cardWidth]);

  useEffect(() => {
    updateMapPadding(height + (placeDetailsIsOpen ? 40 : 20));
  }, [height, placeDetailsIsOpen, updateMapPadding]);

  useEffect(() => {
    if (place.rating) {
      setRating(place.rating ? place.rating : null);
      setRatingDisplay(rating.toFixed(1));

      let ratingColorKey = Math.floor(rating);

      setRatingColor(ratingColors[ratingColorKey]);
    }

    if (place.reviews) {
      if (place.reviews.length > 0) {
        setReviewsString(
          place.reviews
            .map((tip) => {
              let onereviewstring = `${tip.author_name.split(' ')[0]} says: "${tip.text}"`;

              return onereviewstring.toUpperCase();
            })
            .join(" /// ")
        );
        setReviewsView(<marquee scrollamount='4'>{reviewsString}</marquee>);
      } else {
        setReviewsView(
          <marquee scrollamount='4'>UNKNOWN /// UNKNOWN /// UNKNOWN</marquee>
        );
      }
    }

    if (place.photos) {
      let missingPhotosArray = [];
      for (let i = place.photos.length; i < 1; i++) {
        missingPhotosArray.push(missingImage);
        setmissingPhotos(missingPhotosArray);
      }

      let photos = place.photos.slice(0, 3);
      setPhotos(photos);
    }
  }, [place, rating, reviewsString, ratingColors]);

  useEffect(() => {
    setPhotosView(photos.map((photo, index) => {
      return (
        <div
          className='photo'
          style={{
            height: `${Math.floor((cardWidth - 95) / 3)}px`,
            width: `${Math.floor((cardWidth - 95) / 3)}px`,
          }}
          key={photo.id}>
          <img
            src={`https://labs.noshado.ws/google-place-photo/get-pixelated-photo.php?photo_reference=${photo.photo_reference}&api_key=AIzaSyCDaUboSKd_IRDRfe_N0lIJojMkfB1Me9k`}
            alt={`${place.name}`}>
          </img>
        </div>
      );
    }))
  }, [photos, cardWidth, place.name]);

  return (
    <DecorativeFrame
      simple={false}
      className={`place-card ${
        placeDetailsIsOpen ? "place-card--expanded" : ""
      }`}
      style={{
        width: `${cardWidth}px`,
        marginRight: `${rightMargin}px`,
        height: `${height}px`,
      }}>
      <div
        className='content'
        ref={ref}
        style={{
          width: `fit-content`,
          height: `fit-content`,
        }}>
        {placeDetailsIsOpen && (
          <RichCardContent
            place={place}
            cardWidth={cardWidth}
            photos={photos}
            photosView={photosView}
            missingPhotos={missingPhotos}
            simpleAddress={simpleAddress}
            fullAddress={fullAddress}
            ratingDisplay={ratingDisplay}
            rating={rating}
            ratingColor={ratingColor}
            reviewsView={reviewsView}
            getDirections={getDirections}
          />
        )}

        {!placeDetailsIsOpen && (
          <SimpleCardContent
            index={index}
            place={place}
            cardWidth={cardWidth}
            simpleAddress={simpleAddress}
          />
        )}
      </div>
    </DecorativeFrame>
  );
};

PlaceCard.propTypes = {
  place: PropTypes.object,
  cardWidth: PropTypes.number,
  placeDetailsIsOpen: PropTypes.bool,
  rightMargin: PropTypes.number,
  updateMapPadding: PropTypes.func,
};

export default PlaceCard;
