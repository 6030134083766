import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./scanLines.scss";

const ScanLines = ({ lineHeight, height }) => {
  const [scanLines, setScanLines] = useState([]);

  useEffect(() => {
    const newLines = [];
    for (let i = 0; i < height / (lineHeight * 2); i++) {
      newLines.push(i);
    }
    setScanLines(newLines);
  }, [height, lineHeight]);

  return (
    <div className='scan-lines'>
      <div className='noise'></div>

      {scanLines.map((i) => (
        <div
          className='line'
          style={{
            height: `${lineHeight}px`,
            marginBottom: `${lineHeight}px`,
          }}
          id={i}
          key={i}
        />
      ))}
    </div>
  );
};

ScanLines.propTypes = {
  lineHeight: PropTypes.number,
  height: PropTypes.number,
};

export default ScanLines;
