import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import Client from "./Client";
import CompassButton from "./components/compassButton";
import Map from "./components/map";
import PlaceCardCarousel from "./components/placeCardCarousel";
import PlaceCard from "./components/placeCard";
import PlaceCardEmpty from "./components/placeCardEmpty";
import BannerHeader from "./components/bannerHeader";
import BottomChrome from "./components/bottomChrome";
import ButtonContainer from "./components/buttonContainer";
import ChunkyButton from "./components/chunkyButton";
import ReDoSearchButton from "./components/reDoSearchButton";
import ScanLines from "./components/scanLines";
import LoadingScreen from "./components/loadingScreen";
import DecorativeFrame from "./components/decorativeFrame";
import WeatherIndicator from "./components/weatherIndicator";
import HeadsUpDisplay from "./components/headsUpDisplay";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components";
import { ReactComponent as LeftArrow } from "./assets/triangle-left.svg";
import { ReactComponent as RightArrow } from "./assets/triangle-right.svg";
import { weatherThemes } from "./themes";
import { GlobalStyles } from "./globalStyles";
import "./App.scss";

const App = () => {
  // visual stuff --------------------------------------

  const chromePadding = 16;
  const cardSpacing = 12;
  const uiMaxWidth = 700;
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.onresize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
      setPlaceCardWidth(
        window.innerWidth - chromePadding * 2 - 9 < uiMaxWidth
          ? window.innerWidth - chromePadding * 2 - 9
          : uiMaxWidth
      );
    };
  });

  // location setup --------------------------------------

  const { geolocation } = navigator;

  if (!geolocation) {
    console.log("Geolocation is not supported.");
  }

  const handleError = (error) => {
    if (error) {
      switch (error.code) {
        case 1:
          alert(
            "Location access was denied. Please check your browser settings."
          );
          return;

        default:
          alert("Could not determine location.");
          break;
      }
    }
  };

  const gelolocationOptions = useMemo(
    () => ({
      enableHighAccuracy: true,
      maximumAge: 500,
      timeout: 5000,
    }),
    []
  );

  // get initial location --------------------------------------

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [userLocationIsAccquired, setuserLocationIsAccquired] = useState(false);
  const [finishedLoadingPlaces, setFinishedLoadingPlaces] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, long: 0 });

  const handleDetermineInitialLocation = (pos) => {
    const { latitude, longitude } = pos.coords;
    setCurrentLocation({ lat: latitude, long: longitude });
    setTimeout(() => {
      setuserLocationIsAccquired(true);
      setIsFirstLoad(true);
    }, 1500);
  };

  const getInitialLocation = useCallback(() => {
    geolocation.getCurrentPosition(
      handleDetermineInitialLocation,
      handleError,
      gelolocationOptions
    );
  }, [geolocation, gelolocationOptions]);

  useEffect(() => {
    if (!userLocationIsAccquired) {
      if (isFirstLoad) {
        getInitialLocation();
      }
    }
  }, [userLocationIsAccquired, isFirstLoad, getInitialLocation]);

  // weather stuff --------------------------------------

  const [currentTheme, setCurrentTheme] = useState(weatherThemes["default"]);

  const getWeatherTheme = useCallback(() => {
    Client.getWeather(currentLocation.lat, currentLocation.long, (weather) => {
      setCurrentTheme(weatherThemes[weather.themeCode]);
    });
  }, [currentLocation]);

  useEffect(() => {
    if (userLocationIsAccquired) {
      const weatherIntervalID = setInterval(() => {
        getWeatherTheme();
      }, 54000);
      return () => {
        clearInterval(weatherIntervalID);
      };
    }
  });

  // Do stuff on first load (get initial places and weather) --------------------------------------

  const [nearbyPlaces, setNearbyPlaces] = useState([]);

  const getInitialPlaceList = () => {
    Client.getPlaces(
      "coffee",
      "cafe",
      currentLocation.lat,
      currentLocation.long,
      5,
      width,
      height,
      55,
      (places) => setNearbyPlaces(places)
    ).then(setIsFirstLoad(false));
  };

  if (userLocationIsAccquired && isFirstLoad) {
    getInitialPlaceList();
    getWeatherTheme();
  }

  useEffect(() => {
    if (nearbyPlaces.length > 0) {
      setFinishedLoadingPlaces(true);
    } else {
      setFinishedLoadingPlaces(false);
    }
  }, [nearbyPlaces]);

  // watch location and update --------------------------------------

  const locationWatchId = useRef(null);

  const handleWatchLocation = (pos) => {
    const { latitude, longitude } = pos.coords;
    setCurrentLocation({ lat: latitude, long: longitude });
  };

  const handleWatchError = (error) => {
    console.log(error.message);
  };

  const startWatchLocation = useCallback(() => {
    locationWatchId.current = geolocation.watchPosition(
      handleWatchLocation,
      handleWatchError,
      gelolocationOptions
    );
  }, [geolocation, gelolocationOptions]);

  useEffect(() => {
    if (userLocationIsAccquired) {
      startWatchLocation();
      return () => {
        navigator.geolocation.clearWatch(locationWatchId.current);
      };
    }
  }, [userLocationIsAccquired, startWatchLocation]);

  // re-do search --------------------------------------

  const [mapEdges, setMapEdges] = useState([]);
  const [shouldReDoSearch, setShouldReDoSearch] = useState(false);

  const reDoSearch = () => {
    setNearbyPlaces([]);

    Client.getPlaces(
      "coffee",
      "cafe",
      currentLocation.lat,
      currentLocation.long,
      5,
      width,
      height,
      20,
      (places) => setNearbyPlaces(places)
    ).then(
      setTimeout(() => {
        setShouldReDoSearch(false);
      }, 500)
    );
  };

  useEffect(() => {
    if (mapEdges.length > 0) {
      if (
        currentLocation.lat > mapEdges[0] ||
        currentLocation.lat < mapEdges[1] ||
        currentLocation.long < mapEdges[2] ||
        currentLocation.long > mapEdges[3]
      ) {
        setShouldReDoSearch(true);
        getWeatherTheme();
      }
    }
  }, [currentLocation, mapEdges, getWeatherTheme]);

  // place card behavior --------------------------------------

  const [currentPlaceBeingViewed, setCurrentPlaceBeingViewed] = useState(0);
  const [placeDetailsIsOpen, setPlaceDetailsIsOpen] = useState(false);
  const [placeCardWidth, setPlaceCardWidth] = useState(
    width - chromePadding * 2 - 9 < uiMaxWidth
      ? width - chromePadding * 2 - 9
      : uiMaxWidth
  );
  const [mapBottomPadding, setMapBottomPadding] = useState(0);

  const ChangePlaceBeingViewed = (direction) => {
    if (direction === "left") {
      setCurrentPlaceBeingViewed(
        currentPlaceBeingViewed === 0
          ? nearbyPlaces.length - 1
          : currentPlaceBeingViewed - 1
      );
    } else {
      setCurrentPlaceBeingViewed(
        currentPlaceBeingViewed === nearbyPlaces.length - 1
          ? 0
          : currentPlaceBeingViewed + 1
      );
    }
  };

  const TogglePlaceDetails = () => {
    if (placeDetailsIsOpen) {
      setPlaceDetailsIsOpen(false);
    } else {
      setPlaceDetailsIsOpen(true);
    }
  };

  // compass stuff --------------------------------------

  const [rotation, setRotation] = useState(0);
  const [onCompassIsActive, setOnCompassIsActive] = useState();
  const [onCompassInversion, setOnCompassInversion] = useState();

  // component --------------------------------------

  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyles />
      <HelmetProvider>
        <meta
          name='theme-color'
          content={`${currentTheme.statusBarBackground}`}
        />
      </HelmetProvider>
      <div
        className='App'
        style={{
          height: `calc(${height}px + env(safe-area-inset-top))`,
          backgroundColor: `${currentTheme.statusBarBackground}`,
        }}>
        <BannerHeader>
          <DecorativeFrame className='banner-header'>
            <h1>{"Coffee Nearby"}</h1>
          </DecorativeFrame>
        </BannerHeader>

        <ReDoSearchButton
          isVisible={shouldReDoSearch && !placeDetailsIsOpen}
          handleClick={() => reDoSearch()}
        />

        <BottomChrome isExpanded={placeDetailsIsOpen}>
          <HeadsUpDisplay
            isVisible={!placeDetailsIsOpen}
            finishedLoadingPlaces={finishedLoadingPlaces}>
            <WeatherIndicator currentTheme={currentTheme} />
            <CompassButton
              setRotation={setRotation}
              setOnCompassIsActive={setOnCompassIsActive}
              setOnCompassInversion={setOnCompassInversion}
              bottomPos={mapBottomPadding}
            />
          </HeadsUpDisplay>
          <PlaceCardCarousel
            currentPlaceBeingViewed={
              !finishedLoadingPlaces ? 0 : currentPlaceBeingViewed
            }
            finishedLoadingPlaces={finishedLoadingPlaces}
            width={(placeCardWidth + cardSpacing) * nearbyPlaces.length}
            maxWidth={uiMaxWidth}
            screenWidth={width}
            increment={placeCardWidth + cardSpacing}
            defaultPadding={chromePadding + 3}>
            {finishedLoadingPlaces ? (
              nearbyPlaces.length > 0 &&
              nearbyPlaces.map((place, index) => (
                <PlaceCard
                  place={place}
                  index={index}
                  rightMargin={cardSpacing}
                  key={`${place.name}-${index}`}
                  cardWidth={placeCardWidth}
                  placeDetailsIsOpen={placeDetailsIsOpen}
                  updateMapPadding={setMapBottomPadding}
                />
              ))
            ) : (
              <PlaceCardEmpty />
            )}
          </PlaceCardCarousel>

          <ButtonContainer
            direction='horizontal'
            padding={chromePadding + 3}
            maxWidth={uiMaxWidth}>
            <ChunkyButton
              backgroundColor='#45375E'
              shadowColor='#392B4D'
              stretch={false}
              isDisabled={!finishedLoadingPlaces}
              handleClick={() => ChangePlaceBeingViewed("left")}>
              <LeftArrow />
            </ChunkyButton>

            <ChunkyButton
              backgroundColor='#45375E'
              shadowColor='#392B4D'
              stretch={false}
              isDisabled={!finishedLoadingPlaces}
              handleClick={() => ChangePlaceBeingViewed("right")}>
              <RightArrow />
            </ChunkyButton>

            <ChunkyButton
              backgroundColor='#F45757'
              shadowColor='#C34646'
              stretch={true}
              isDisabled={!finishedLoadingPlaces}
              handleClick={TogglePlaceDetails}>
              {placeDetailsIsOpen ? "Hide" : "View"} Info
            </ChunkyButton>
          </ButtonContainer>
        </BottomChrome>

        <ScanLines lineHeight={2} height={height} />

        <LoadingScreen
          userLocationIsAccquired={userLocationIsAccquired}
          backgroundColor={currentTheme.statusBarBackground}
          height={height}
        />

        {userLocationIsAccquired && (
          <Map
            mapSize={{
              width: window.innerWidth,
              height: height,
            }}
            rotationDeg={rotation}
            userLat={currentLocation.lat}
            userLong={currentLocation.long}
            places={nearbyPlaces}
            currentPlaceBeingViewed={currentPlaceBeingViewed}
            placeDetailsIsOpen={placeDetailsIsOpen}
            setMapEdges={setMapEdges}
            shouldReDoSearch={shouldReDoSearch}
            bottomPadding={mapBottomPadding}
            compassIsActive={onCompassIsActive}
            compassIsInverted={onCompassInversion}
          />
        )}
      </div>
    </ThemeProvider>
  );
};

export default App;
